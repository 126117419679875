 

<template>
  <div class="flex-col page">
    <div class="flex-col group_1">
       <div class="step">
            <div class="backing" @click="back()">
                <van-icon name="revoke" class="icon" color="#FFFFFF"/>
            </div>
            <h2>选择工单类型</h2>
            <div class="btn" @click="skip('/property/add-alarm')">
                <van-button type="primary" color="#005DDB" block>电梯维修工单</van-button>
            </div>
            <div class="btn" @click="skip('/property/add-rescue')">
                <van-button type="primary" color="#005DDB" block>电梯救援工单</van-button>
            </div>
        </div>
    </div>
    <!-- 操作栏 -->
  
    <div class="justify-between tab-bar">
        <div class="flex-col items-center" @click="index()">
          <img src="../../assets/icon33.png" class="image_20" />
          <span>首页</span>
        </div>
        <div class="flex-col items-center" @click="step()">
          <img src="../../assets/stepicon_blue.png" class="image_20" />
          <span>发布</span>
        </div>
        <div class="flex-col items-center" @click="my()">
          <img src="../../assets/icon12.png" class="image_20" />
          <span class="text_55">我的</span>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    methods:{
        index(){
            this.$router.push('/property/index')
        },
        my(){
            this.$router.push('/property/my')
        },
        step(){
            this.$router.push('/property/step')
        },
        skip(url){ //跳转 
            this.$router.push(url)
        }
    },
    mounted(){
        // setTimeout(()=>{location.reload ()},1000)
    }
}
</script>

<style scoped>
@import '../../assets/common.css';
.left-group_1 {
  width: 1.81rem;
}
.image_20 {
  width: 1.69rem;
  height: 1.69rem;
}
.list-item {
  padding: 1rem 0.5rem;
  background: #fff;
  border-radius: 0.3rem;
}
.list-item_1 {
  padding: 1.06rem 0.63rem;
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
  background-color: rgb(252, 252, 252);
}
.image_23 {
  width: 1.69rem;
  height: 1.69rem;
}
.left-group {
  color: rgb(0, 0, 0);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.right-group {
  margin-right: 0.13rem;
  color: rgb(255, 255, 255);
  font-size: 0.63rem;
  line-height: 0.56rem;
  white-space: nowrap;
}
.image_3 {
  width: 0.94rem;
  height: 1rem;
}
.text_4 {
  margin-left: 0.81rem;
}
.image_13 {
  width: 0.81rem;
  height: 1rem;
}
.text_10 {
  margin-left: 0.81rem;
}
.page {
  background-color: rgb(245, 245, 245);
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.section_1 {
  padding: 0.88rem 0.81rem;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
  background-color: rgb(237, 237, 237);
}
.group_1 {
  padding-bottom: 5.38rem;
  flex: 1 1 auto;
  overflow-y: auto;
}
.tab-bar {
  padding: 0.28rem 4.94rem 0.31rem 5.06rem;
  color: rgb(0, 0, 0);
  font-size: 0.63rem;
  /* line-height: 0.63rem; */
  white-space: nowrap;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px -0.25rem 0.94rem rgba(0, 0, 0, 0.18);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.image_1 {
  margin-right: 0.25rem;
  align-self: center;
  width: 1.13rem;
  height: 0.25rem;
}
.section_2 {
  padding-bottom: 0.31rem;
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  line-height: 1.44rem;
  white-space: nowrap;
  background-image: url('../../assets/imgbg6.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
}
.section_3 {
  margin: -3.75rem 1.25rem 0;
  padding: 1.25rem 1rem;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0.19rem 0.75rem rgba(0, 0, 0, 0.16);
  border-radius: 0.63rem;
  position: relative;
}
.list {
  padding: 1.25rem 1.19rem 0.63rem 1.25rem;
}
.list_1 {
  padding-left: 1.25rem;
  padding-right: 1.19rem;
}
.section_12 {
  margin-top: 0.22rem;
  width: 0.13rem;
  height: 1.38rem;
  border: solid 0.063rem rgb(57, 92, 154);
}
.image {
  margin-bottom: 0.13rem;
  width: 0.81rem;
  height: 0.81rem;
}
.text {
  margin-left: 0.69rem;
}
.text-wrapper {
  padding: 1.81rem 0 5.19rem;
  width: 11.25rem;
}
.image_2 {
  filter: drop-shadow(0px 0.19rem 0.44rem rgba(0, 0, 0, 0.16));
  width: 4.25rem;
  height: 4.25rem;
}
.group_2 {
  margin-left: 0.44rem;
  margin-top: 1.06rem;
  flex: 1 1 auto;
}
.text_16 {
  margin-top: 0.094rem;
}
.text_1 {
  margin-left: 1.31rem;
}
.text_2 {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  line-height: 1rem;
  white-space: nowrap;
}
.text_3 {
  margin-top: 0.63rem;
  color: rgb(102, 102, 102);
  font-size: 0.88rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.group_3 {
  width: 1.25rem;
  position: relative;
}
.image_6 {
  margin: 0.13rem 0 0.13rem 0.5rem;
  width: 0.5rem;
  height: 0.88rem;
}
.image_5 {
  width: 1.13rem;
  height: 1.13rem;
  background: red;
  border-radius: 50%;
  text-align: center;
  line-height: 1.13rem;
}
.text_6 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.tab-bar {
  padding: 0.34rem 4.13rem 0.5rem 4.13rem;
  color: rgb(0, 0, 0);
  font-size: 0.63rem;
  line-height: 0.63rem;
  white-space: nowrap;
  /* filter: drop-shadow(0px 0px 0.94rem rgba(0, 0, 0, 0.1)); */
  background-image: url('../../assets/icon3.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.section_15 {
  align-self: center;
  width: 0.13rem;
  height: 1.31rem;
  border: solid 0.063rem rgb(57, 92, 154);
}
.text_55 {
  margin-top: 0.094rem;
}
</style>
<style scoped>
    .step{
        width: 80%;
        margin-left: 2rem;
        margin-top: 2rem;
        overflow: hidden;
    }
    #step .step h2{
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 0px;
        color: #000000;
        opacity: 1;
        line-height: 34px;
    }
    .btn{
        margin-top: 1rem;
        border-radius: 0.6rem;
        overflow: hidden;
    }
    .backing{
        width: 2rem;
        height: 2rem;
        background: #005DDB;
        border-radius: 1rem;
    }
    .icon{
        left: 25%;
        top: 25%;
    }
</style>>